import "./App.css"

function App() {
  return (
    <div className="App">
      
      <div className="container">
        <div className="info-card">
          <h1>
            <span>Webinen</span>
            <span>Support</span>
            <span>Page</span>
          </h1>
          <div className="shinning-box" />
        </div>
      </div>

    </div>
  );
}

export default App;
